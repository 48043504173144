.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  gap: 20px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  overflow: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

body::-webkit-scrollbar {
  display: none;
}

.winner-message {
  position: absolute;
  top: 30px;
  width: 95%;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #4caf50;
  z-index: 1000;
}

.spin-button {
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.spin-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.spin-button:hover:not(:disabled) {
  background-color: #45a049;
  transform: scale(1.05);
}